import toast from 'react-hot-toast';
import config from '../config';
import useStore from '../store/store';

export const brandsService = async (token) => {
  try {
    const { pricetool_brands, setPricetool_brands } = useStore.getState();
    if (pricetool_brands.length > 0) {
      return; // Do not fetch brands if they are already in the store 
    }

    const brandsResponse = await fetch(`${config.serverUrl}/brands`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      credentials: 'include',
    });

    if (!brandsResponse.ok) {
      throw new Error('Failed to fetch brands');
    }

    const brandsData = await brandsResponse.json();
    setPricetool_brands(brandsData.brands); // Update the store with the fetched brands.
  } catch (brandsError) {
    console.error('Error fetching brands:', brandsError);
    toast.error('Failed to fetch brands');
  }
};
