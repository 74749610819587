const envMap = {
  localhost: "https://localhost:4000",
  dev: "https://8x5q0lbfjd.execute-api.eu-central-1.amazonaws.com",
  prd: "https://nog0yazwyf.execute-api.eu-central-1.amazonaws.com",
};

const currentEnv = process.env.REACT_APP_NODE_ENV || 'prd';

const config = {
  serverUrl: envMap[currentEnv] || envMap.prd, // Fallback a 'prd' si el entorno no coincide
  beta: false,
  banner_christmas_break: true,
};

export default config;
