
/**
 * Clean the input number and calculate the discounted price
 * @param {*} price 
 * @param {*} discount 
 * @returns formatted price.
 */
export const getNettolPrice = (price, discount) => {
  let cleanedPrice = price.replace(/[^0-9,.-]+/g, '').replace(',', '.');   
  let numericPrice = parseFloat(cleanedPrice);    
  if (isNaN(numericPrice)) {
    throw new Error('Invalid price format');
  }   
  let netPrice = numericPrice - ((numericPrice * discount) / 100);
  return netPrice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
};


/**
 * Format weight regarding the unit
 * @param {*} weight 
 * @param {*} unit 
 * @returns formatted weight
 */
export const getWeightByUnit = (weight, unit) => {
  if (isNaN(weight)) {
    return ' - ';
  }
  if (unit === 'kg') {
    return `${parseFloat(weight).toFixed(2).replace('.', ',')} kg`;
  } else if (unit === 'g') {
      return `${Math.round(weight)} g`;
  }
  return `${weight} ${unit}`;
};


/**
 * Checks if the current date is between December 20th and January 1st (inclusive).
 * @returns {boolean} - Returns true if the current date is within the range, otherwise false.
 */
export const isHolidayBannerVisible = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  // Define the range limits
  const startOfHoliday = new Date(`${currentYear}-12-17`); // December 18th of the current year
  const endOfHoliday = new Date(`${currentYear + 1}-01-01`); // January 1st of the next year (inclusive)
  
  const adjustedEndOfHoliday = today.getMonth() === 0 && today.getDate() <= 1
    ? new Date(`${currentYear}-01-01`)
    : endOfHoliday;

  // Check if the current date is within the range
  return today >= startOfHoliday && today <= adjustedEndOfHoliday;
};



