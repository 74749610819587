import React from "react";
import { Box, Card, CardContent, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { getNettolPrice, getWeightByUnit } from "../../utils/calculation";
import useStore from "../../store/store";
import { useTranslation } from 'react-i18next';

const SearchResultsItem = (props) => {
  const { t } = useTranslation();
  const {row, index} = props;
  const {
    isLoadingResults,
  } = useStore();
  
  // +++++++++++++++++++++++++++++
  // STYLES:
  // +++++++++++++++++++++++++++++
  const styles = {
    card: {
      marginBottom: '0.5rem',
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
      border: '1px solid #e4e4e4',
    },
    cardContent: {
      padding: '1rem 1.5rem',
    }
  };
  // +++++++++++++++++++++++++++++
  

  return (
    <>
    {isLoadingResults ? (
      <Skeleton height={100} />
    ) : (
    <Card key={index} style={styles.card} variant="elevation" >
      <CardContent sx={{px:1.5, py:1, '&:last-child': {pb: '5px'}}}>
        <Grid container spacing={0} sx={{ p: 0, m: 0, minHeight: 0 }}>  
          <Grid item xs={5}>
            <Box>
              <Typography sx={{fontSize: 13, color: '#566573', lineHeight: 1}}>
                {row.brand}
              </Typography>
              
              <Typography sx={{fontSize: 19, fontWeight: 'bold', color: '#566573', lineHeight: 1}}>
                {row.artnr}
              </Typography>

              <Typography sx={{fontSize: 15, fontWeight: 'bold', color: 'slategray', lineHeight: 1 }}>
                {row.name}
              </Typography>

              { (typeof row.onstock === "string" ? parseFloat(row.onstock.trim()) === 0 : row.onstock === 0) && (
                <Typography sx={{fontSize: 13, color: 'SteelBlue', lineHeight: 1, mt:1, fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                  <span style={{fontSize: 16, lineHeight: 1, verticalAlign: 'middle'}}>📅</span>
                  {t('resultItemDeliveryTime')}:&nbsp; 
                  {row.delivery_time_weeks}&nbsp;
                  {row.delivery_time_weeks === '1' ? t('resultItemWeek') : t('resultItemWeeks')}
                </Typography>
              )}

              { parseFloat(row.onstock.trim()) > 0 && (
                <Typography sx={{fontSize: 13, color: '#48c9b0', lineHeight: 1, mt:1, fontWeight: 'bold'}}>
                <span
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: '#48c9b0',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: 4,
                  }}
                />
                {t('resultItemStock')}: {parseFloat(row.onstock)}&nbsp; 
                {parseFloat(row.onstock) === 1 ? t('resultItemUnit') : t('resultItemUnits')}
                </Typography>
              )}

            </Box>
          </Grid>

          <Grid item xs={7} >
            <Grid container spacing={3} sx={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <Typography sx={{ fontSize: 12, lineHeight: 1.5 }}>
                  {t('resultItemListPrice')}
                </Typography>
                <Typography sx={{ fontSize: 18, lineHeight: 1.7, whiteSpace: "nowrap", fontWeight: "bold", color: "slategray" }}>
                  {row.listprice.replace(".", ",").toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'}
                </Typography>
              </Grid>

              <Grid item>
                <Typography align="center" sx={{ fontSize: 12, lineHeight: 1.5 }}>{t('resultItemDiscount')}</Typography>

                <Tooltip 
                  title={
                    row.discountgroup && row.discountgroup.trim() !== ''
                      ? t('resultItemDiscountGroup', { discountGroup: row.discountgroup.toUpperCase() })
                      : t('resultItemDiscountGroupNotSet')
                  }
                  placement="bottom"
                  arrow 
                  slotProps={{
                    tooltip: {
                      sx: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        backgroundColor: '#d6eaf8',
                        color: '#3498db',
                        padding: '10px',
                        borderRadius: '10px',
                        boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                      },
                    },
                    arrow: { sx: {color: '#d6eaf8'} },
                  }}                       
                  >
                  
                  {row.discount !== null? (
                    <Typography align="center" sx={{fontSize:18,lineHeight:1.7,fontWeight:"bold",background:'#e7f2f9',borderRadius:20,cursor:'pointer',color:'#3498db', px:1}}>
                      {row.discount + '%'}
                    </Typography>
                  ) : (
                    <Typography sx={{lineHeight:2.9, cursor:'pointer', borderBottom:'2px dotted grey'}} color='grey' variant='caption'>
                      {t('resultItemOnRequest')}
                    </Typography>
                  )}
                </Tooltip>
              </Grid>

              <Grid item>
                <Typography align="center" sx={{ fontSize: 12, lineHeight: 1.5 }}>
                  {t('resultItemYourPrice')}
                </Typography>
                <Typography align="center" sx={{ fontSize: 18, lineHeight: 1.7, whiteSpace: "nowrap", fontWeight: "bold", color: "SteelBlue" }}>
                  {row.discount !== null ? getNettolPrice(row.listprice, row.discount) : 
                  <Typography color='grey' variant='caption'>
                    {t('resultItemOnRequest')}
                  </Typography>}

                  {row.metalsur && row.metalsur === '999' && (
                    <Tooltip 
                      title={t('resultItemMetalSurchargeWarning')}
                      placement="bottom"
                      arrow 
                      slotProps={{
                        tooltip: {
                          sx: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            backgroundColor: '#fadbd8',
                            color: '#c0392b',
                            padding: '10px',
                            borderRadius: '10px',
                            boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                          },
                        },
                        arrow: { sx: {color: '#fadbd8'} },
                      }}                       
                    >
                      <InfoIcon sx={{ ml: 0.5, mb: '4px', verticalAlign: 'middle', color: '#e74c3c', fontSize: 24, cursor: 'pointer' }} />
                    </Tooltip>
                  )}

                  {row.base_unit && row.base_unit.toLowerCase().trim() === 'cm²' && (
                    <Tooltip 
                      title={t('resultItemEstimatedPriceWarning')}
                      placement="bottom"
                      arrow 
                      slotProps={{
                        tooltip: {
                          sx: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            backgroundColor: '#ebdef0',
                            color: '#9b59b6',
                            padding: '10px',
                            borderRadius: '10px',
                            boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                          },
                        },
                        arrow: { sx: {color: '#ebdef0'} },
                      }}                       
                    >
                      <InfoIcon sx={{ ml: 0.5, mb: '4px', verticalAlign: 'middle', color: '#9b59b6', fontSize: 24, cursor: 'pointer' }} />
                    </Tooltip>
                  )}

                </Typography>
              </Grid>

              <Grid item>
                <Typography align="center" sx={{ fontSize: 12, lineHeight: 1.5 }}>{t('resultItemMOQ')}</Typography>
                <Typography align="center" sx={{ fontSize: 18, lineHeight: 1.7, whiteSpace: "nowrap", fontWeight: "bold", color: "slategray" }}>
                  {row.moq !== null ? row.moq : ' - '}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{mt: 3}}>
              <div style={{display: "flex", justifyContent: "flex-end", width: "100%", marginTop:10}}>
                <div style={{display: "flex", justifyContent: "flex-end", width: "90%", gap: '1.5rem'}}>
                  <div>
                    <Typography sx={{ fontSize: 12, color: '#999', lineHeight: 1 }}>
                      <span style={{whiteSpace: "nowrap"}}>
                        {t('resultItemWeight')}:&nbsp;
                        {
                          (row.weight > 0 && row.weightunit) ? getWeightByUnit(row.weight, row.weightunit) : ' - '
                        }
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: 12, color: '#999', lineHeight: 1 }}>
                      <span style={{whiteSpace: "nowrap"}}>
                      {t('resultItemCustom')}:&nbsp;{row.ctn !== null ? row.ctn : ' - '} 
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: 12, color: '#999', lineHeight: 1 }}>
                      <span style={{whiteSpace: "nowrap"}}>                      
                      {t('resultItemOrigin')}:&nbsp;{row.origin !== null ? row.origin : ' - '}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
    )}
    </>
  );  
};
export default SearchResultsItem;