import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useStore from '../../store/store';
import Faqs from './Faqs.js';
import BrandsList from './BrandsList'; // Importamos el nuevo componente
import { useTranslation } from 'react-i18next';
import config from '../../config.js';
import { isHolidayBannerVisible } from '../../utils/calculation.js';

const SearchWelcomeHeader = () => {
  const { t } = useTranslation();
  const { hasSearched, setShouldFocus } = useStore();

  const handleCTAClick = () => {
    setShouldFocus(true);
  };

  return (
    <Box>
      {!hasSearched && (
        <Box id="box_info">

          {/* Show banner if config.banner_christmas_break es true */}
          {config.banner_christmas_break && isHolidayBannerVisible() && (
            <Box sx={{ margin: '1em 0'	, background: 'gold', opacity: 0.8, color: 'white', padding: '0.5em 1em' }}>
              <Typography variant="body2" color={'black'}>
                <b>{t('christmasBreak_title')}</b>: {t('christmasBreak_text')} -- {t('christmasBreak_advice1')}                
              </Typography>
            </Box>
          )}
          {/* End of banner */}

          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t('welcomeExploreTitle')}
          </Typography>
          <br />

          <Typography>{t('welcomeDescription')}</Typography>

          <BrandsList />

          <Typography>{t('welcomeEnquiries')}</Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCTAClick}
            style={{ margin: "0 auto", display: "block", padding: "10px 20px" }}
          >
            {t('welcomeStartSearch')}
          </Button>

          <Faqs />
        </Box>
      )}
    </Box>
  );
};

export default SearchWelcomeHeader;
