import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SellIcon from '@mui/icons-material/Sell';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useStore from '../store/store';
import { useNavigate } from 'react-router-dom';
import { handleSubmitService } from '../services/loginService';
import { Toaster } from 'react-hot-toast';
import { IconButton, InputAdornment, Paper } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LanguageSelector from '../components/LanguageSelector';
import LoadingOverlay from '../components/SearchEngine/LoadingOverlay';

const defaultTheme = createTheme({
  palette: {
    primary: { 
      main: '#1f56ae' 
    },
    background: {
      default: '#FEFEFE'
    }
  },
  typography: {}
});

export default function SignIn() {
  const { t } = useTranslation();
  const setToken = useStore((state) => state.setToken);
  const setUser = useStore((state) => state.setUser);
  const setRememberMe = useStore((state) => state.setRememberMe);
  const rememberMe = useStore((state) => state.rememberMe || true);
  const isLoadingUser = useStore((state) => state.isLoadingUser);
  const navigate = useNavigate();

  const [email, setEmail] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const handleEmail = () => {
    const u = 'info';
    const d = 'automationprices';
    const t = 'com';
    const m = `${u}@${d}.${t}`;
    setEmail(m);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <LoadingOverlay loadingCondition={isLoadingUser} />
      <Container component="main" maxWidth="xs" sx={{ display:"block", justifyContent: "center", alignItems: "center", pt: 4 }}>
        <Paper elevation={1} sx={{ p: 3, mt: 3, background: "#f6f7f880" }} >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 0, flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 0 }}>
                  <SellIcon
                    sx={{
                      mr: 0.5,
                      opacity: 0.3,
                      color: "primary.main",
                      fontSize: { xs: "0rem", sm: "2.5rem" },
                    }}
                  />
                  <Typography component="h1" variant="h3" sx={{ fontWeight: "bold", color: "primary.main" }}>
                    {t('title')}
                  </Typography>
                </Box>
              </Box>
              <Typography component="h2" variant="h5">
                {t('signIn')}
              </Typography>
            <Box component="form" onSubmit={(e) => handleSubmitService(e, setToken, setUser, navigate)} noValidate sx={{ mt: 1 }}>
              <TextField
                sx={{background: 'white'}}
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('emailAddress')}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                sx={{background: 'white'}}
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('password')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('togglePasswordVisibility')}
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormControlLabel
                  control={
                    <Checkbox value="remember" id="rememberMe" color="primary" 
                      checked={rememberMe} 
                      onChange={(e) => setRememberMe(e.target.checked)} />
                  }
                  label={t('rememberMe')}
                />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoadingUser}
              >
                {isLoadingUser ? `${t('loading')} ... `: t('signIn')}
              </Button>
              
              <Grid container>
                <Grid item xs sx={{ py: 4 }}>
                <Link
                  href={email ? `mailto:${email}` : '#'}
                  onClick={handleEmail}
                  variant="body2"
                >
                    {t('noAccountOrForgotPassword')} <br />
                    {t('contactUs')}
                  </Link>
                </Grid>
              </Grid>

            </Box>
          </Box>
          
          <Toaster
            position="bottom-center"
            toastOptions={{
              className: '',
              success: {
                style: {
                  background: 'Azure',
                  border: '3px solid green',
                  color: 'green',
                },
              },
              error: {
                style: {
                  background: 'LavenderBlush',
                  border: '3px solid red',
                  color: 'red',
                },
              },
            }}
          />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ mt: 2 }}>
            <LanguageSelector />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
